
<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import test from "@/app.config.json";
  import moment from 'moment'
  import axios from "axios";
  import DatePicker from "vue2-datepicker";
  import 'vue2-datepicker/index.css';

  export default {
    page: {title: "EINSTUDIO-Homework",meta: [{name: "description"}]},
    components: {
      Layout,PageHeader,DatePicker
    },data() {
      return {
        title: "HOMEWORKS",
        items: [{text: "Home",href:"/"}, {text: "Group",href:"/groupdetails/"+this.$route.params.groupId},{text: "Homeworks",active: true}],
        baseUrl:test.baseUrl,fileUrl:test.fileUrl,user:[],files:[],groupId:"",uploadedFiles: [],myGorups:[],deliveryDate: null,startDate: null,explanation:"",id:"",name:"",link:"",videoLink:""
      };
      },created:function(){
        this.user = JSON.parse(localStorage.getItem('user'));
        this.groupId=this.$route.params.groupId
        this.loadData();
      },methods: {
         loadData(){
          let loader= this.$loading.show();
          axios.get(this.baseUrl+"/Reminder/GetList?status=true&groupId="+this.groupId,{
            headers:{Authorization: `Bearer ${this.user.jwt}` }
          }).then((response)=>{
             this.files=response.data;
             loader.hide();
          }).catch((error)=>{
           const html ="<p>"+ error.response.data.message+"</p>";
           this.$swal("",html,"error");
          })
      },addData(){
         let loader = this.$loading.show();

         var formToPost = new FormData(document.getElementById("add-file-form"));

         formToPost.append("userId",this.user.id)
         formToPost.append("groupId",this.groupId)
         formToPost.append("status",true)
         formToPost.append("reminderStartDate",moment(this.startDate).format("YYYY-MM-DDTHH:mm") )
         formToPost.append("reminderEndDate",moment( this.deliveryDate).format("YYYY-MM-DDTHH:mm"))

         axios.post(`${this.baseUrl}/Reminder/Add`, formToPost, {
          headers: {
            Authorization: `Bearer ${this.user.jwt}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => 
        {
          loader.hide();
          this.$swal("",response.data.message, "success");
          this.loadData();
          this.hideModal('modal-lg');
        })
        .catch((error) => 
        {
          loader.hide();
          var html="";
          if(error.response.data.length>0)
          {
            for (let index = 0; index < error.response.data.length; index++) 
            {
              html +="<small>"+ error.response.data[index].message+"</small><hr>";
            }
          }
          else
          {
            html ="<p class='small'>"+ error.response.data.message+"</p>";
          }
          this.$swal("",html,"error");
        });
      },editData(){
         let loader = this.$loading.show();

         var formToPost = new FormData(document.getElementById("edit-file-form"));

         formToPost.append("userId",this.user.id)
         formToPost.append("groupId",this.groupId)
         formToPost.append("status",true)
         formToPost.append("reminderStartDate", moment(this.startDate).format("YYYY-MM-DDTHH:mm"))
         formToPost.append("reminderEndDate",moment( this.deliveryDate).format("YYYY-MM-DDTHH:mm"))
         formToPost.append("id",this.id)

         axios.put(`${this.baseUrl}/Reminder/Set`, formToPost, {
          headers: {
            Authorization: `Bearer ${this.user.jwt}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => 
        {
          loader.hide();
          this.$swal("",response.data.message, "success");
          this.loadData();
          this.hideModal('modal-edit');
        })
        .catch((error) => 
        {
          loader.hide();
          var html="";
          if(error.response.data.length>0)
          {
            for (let index = 0; index < error.response.data.length; index++) 
            {
              html +="<small>"+ error.response.data[index].message+"</small><hr>";
            }
          }
          else
          {
            html ="<p class='small'>"+ error.response.data.message+"</p>";
          }
          this.$swal("",html,"error");
        });
      },format_date(value)
      {
         if (value) 
         {
           return moment(String(value)).format('DD.MM.YYYY HH:mm')
          }
      },notBeforeToday(date) {
         return date < new Date(new Date().setHours(0, 0, 0, 0));
      },initialData(id){
         // let loader= this.$loading.show();
          axios.get(`${this.baseUrl}/Reminder/GetById/`+id, { headers: { Authorization: `Bearer ${this.user.jwt}`},
          }).then((response) => 
          {
            this.name= response.data.name;
            this.explanation=response.data.explanation;
            this.startDate=moment(response.data.reminderStartDate).format('YYYY-MM-DDTHH:mm');
            this.deliveryDate=moment(response.data.reminderEndDate).format('YYYY-MM-DDTHH:mm');
            this.groupId=response.data.groupId;
            this.id=response.data.id;

           // loader.hide();
          })
          .catch((error) => 
          {
            //loader.hide();
            var html ="<p class='small'>"+ error.response.data.message+"</p>";
            this.$swal("",html,"error");
          });
      },deleteData(id){
          let loader= this.$loading.show();
          axios.delete(`${this.baseUrl}/Reminder/Delete/`+id, { headers: { Authorization: `Bearer ${this.user.jwt}`},
          }).then((response) => 
          {
            this.loadData();
            loader.hide();
            this.$swal("",response.data.message, "success");
          })
          .catch((error) => 
          {
            loader.hide();
            var html ="<p class='small'>"+ error.response.data.message+"</p>";
            this.$swal("",html,"error");
          });
      },deleteConfirm(id){
        this.$swal({ 
          title: "WARNING",
          text: "Are you sure? Will be deleted along with the connected data.",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Yes",
          cancelButtonText:"No",
          }).then((result) => {
            if (result.value) 
            {
              this.deleteData(id)
            }
          });
      },showModal(modalid) {
        this.$root.$emit('bv::show::modal',modalid)
      },hideModal(modalid) {
        this.$root.$emit('bv::hide::modal',modalid)
      }
     }
  };
</script>
<style>
.cover-image{
  width: 100%;
    height: 100px;
    object-fit: cover;
}
</style>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row mb-3">
        <div class="col-lg-12">
          <a type="button" id="add-modal"   v-b-modal.modal-lg  class="btn btn-success"><i class="fa fa-cogs"></i> + ADD NEW WORK</a>
        </div>
    </div>
    <div class="row" id="categories-div">
        <div class="col-md-4" v-for="(item, index) in files" :key="index">
            <div class="card">
              <div class="card-header bg-info text-white">
                <p class="text-truncate font-size-14 mb-2"><i class="fa fa-cogs"></i> {{item.name}} <i 
                              v-if="(item.groupId!=null && item.groupId!='')" class="fa fa-users float-right" v-b-tooltip.hover :title="item.group.name"></i></p>
              </div>
                    <div class="card-body">
                    <div class="d-flex">
                        <div class="flex-1 overflow-hidden" style="width:100%">
                          <p><i class="fa fa-user"></i> {{ item.user.fullName }}</p>
                
                            <p class="text-truncate font-size-14 mb-2"><i class="fa fa-calendar"></i> {{ format_date(item.reminderStartDate)}} - <strong v-b-tooltip.hover title="Homework Delivery Date" class="text-white bg-danger bold p-1">{{ format_date(item.reminderEndDate) }}</strong></p>
                            <p class="text-truncate font-size-14 mb-2">{{item.explanation}}</p>
                        </div>
                    </div>
                </div>
                <div class="card-body border-top py-3">
                    <div class="text-truncate">
                        <router-link :to="{ path: '/reminderdetail/'+item.id}" tag="a"  v-b-tooltip.hover title="Homeworks" class="btn btn-sm btn-info"><i class="fa fa-file"></i> {{item.homeworks.length}}</router-link>
                        <router-link :to="{ path: '/reminderdetail/'+item.id}" tag="a"  v-b-tooltip.hover title="Detail" class="btn btn-sm btn-info float-right ml-1"><i class="fa fa-eye"></i></router-link>
                        <a type="button" v-if="user.rol=='admin' | user.id==item.userId" v-b-tooltip.hover title="Delete" class="btn btn-sm btn-danger float-right ml-1"  @click="deleteConfirm(item.id)"><i class="fa fa-trash"></i></a>
                        <a type="button" v-if="user.rol=='admin' | user.id==item.userId"  v-b-modal.modal-edit  v-b-tooltip.hover title="Edit" class="btn btn-sm btn-warning float-right ml-1"  @click="initialData(item.id)"><i class="fa fa-edit"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="modal-lg" size="lg" title="New Work Item" title-class="font-18" hide-footer>
        <form @submit.prevent="addData()" id="add-file-form">
            
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Work Name (*)</label>
                        <input type="text" class="form-control" name="name" autocomplete="off"/>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label>Start Date</label>
                        <date-picker v-model="startDate" :first-day-of-week="1" lang="tr" name="reminderStartDate" type="datetime" value-type="YYYY-MM-DDTHH:mm" format="YYYY-MM-DDTHH:mm" :minute-step="15" :disabled-date="notBeforeToday" aria-required="true"></date-picker>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label>Delivery Date</label>
                        <date-picker v-model="deliveryDate" :first-day-of-week="1" lang="tr" name="reminderEndDate" type="datetime" value-type="YYYY-MM-DDTHH:mm" format="YYYY-MM-DDTHH:mm" :minute-step="15" :disabled-date="notBeforeToday" aria-required="true"></date-picker>
                    </div>
                </div>
                <div class="col-sm-4">
                  <label>homework File</label>
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="file" name="file" accept=".jpg,.png,.JPG,.PNG,.jpeg,.JPEG,.pptx,.docx,.xlsx,.pdf,.txt,.rtf">
                      <label class="custom-file-label" for="file">Choose File</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Explanation</label>
                        <textarea name="explanation" class="form-control"></textarea>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-12">
                    <button type="submit" class="btn btn-warning"><i class="fa fa-check"></i> SAVE WORK</button>
                </div>
            </div>
        </form>
    </b-modal>
    <b-modal id="modal-edit" size="lg" title="Edit Work Item" title-class="font-18" hide-footer>
        <form @submit.prevent="editData()" id="edit-file-form">
            
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                      <label>Work Name (*)</label>
                        <input type="text" class="form-control" name="name" v-model="name" autocomplete="off"/>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label>Start Date</label>
                        <date-picker v-model="startDate" :first-day-of-week="1" lang="tr" name="reminderStartDate" type="datetime" value-type="YYYY-MM-DDTHH:mm" format="YYYY-MM-DDTHH:mm" :minute-step="15" :disabled-date="notBeforeToday"></date-picker>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label>Delivery Date</label>
                        <date-picker v-model="deliveryDate" :first-day-of-week="1" lang="tr" name="deliveryDate" type="datetime" value-type="YYYY-MM-DDTHH:mm" format="YYYY-MM-DDTHH:mm" :minute-step="15" :disabled-date="notBeforeToday"></date-picker>
                    </div>
                </div>
                <div class="col-sm-4">
                  <label>homework File</label>
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="file" name="file" accept=".jpg,.png,.JPG,.PNG,.jpeg,.JPEG,.pptx,.docx,.xlsx,.pdf,.txt,.rtf">
                      <label class="custom-file-label" for="file">Choose File</label>
                    </div>
                </div>
            </div>
          
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Explanation</label>
                        <textarea name="explanation" class="form-control" v-model="explanation"></textarea>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-12">
                    <button type="submit" class="btn btn-warning"><i class="fa fa-check"></i> SAVE WORK</button>
                </div>
            </div>
        </form>
    </b-modal>
  </Layout>
</template>